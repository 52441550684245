import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

// Configuration de vuex-persist pour persister l'état dans localStorage
new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    projectsContent: state.projectsContent,
    aboutContent: state.aboutContent,
    aboutContent2: state.aboutContent2,
    experiencesContent: state.experiencesContent,
    degreesContent: state.degreesContent,
    blogContent: state.blogContent,
    headerContent: state.headerContent,
    analytics: state.analytics,
  }),
});

export default createStore({
  state: {
    projectsContent: "",
    aboutContent: "",
    aboutContent2: "",
    experiencesContent: "",
    degreesContent: "",
    blogContent: [],
    headerContent: "",
    analytics: false,
    randomCharacterQuote: null,
    currentPage: 1,
    hasMorePosts: true,
  },
  getters: {
    projectsContent: (state) => state.projectsContent,
    aboutContent: (state) => state.aboutContent,
    aboutContent2: (state) => state.aboutContent2,
    experiencesContent: (state) => state.experiencesContent,
    degreesContent: (state) => state.degreesContent,
    blogContent: (state) => state.blogContent,
    headerContent: (state) => state.headerContent,
    randomCharacterQuote: (state) => state.randomCharacterQuote,
    currentPost: (state) => state.currentPost,
  },
  mutations: {
    SET_PROJECTS(state, projectsContent) {
      state.projectsContent = projectsContent;
    },
    SET_ABOUT(state, aboutContent) {
      state.aboutContent = aboutContent;
    },
    SET_ABOUT2(state, aboutContent2) {
      state.aboutContent2 = aboutContent2;
    },
    SET_EXPERIENCE(state, experiencesContent) {
      state.experiencesContent = experiencesContent;
    },
    SET_DEGREES(state, degreesContent) {
      state.degreesContent = degreesContent;
    },
    SET_BLOG(state, content) {
      state.blogContent = content;
    },
    APPEND_BLOG(state, content) {
      state.blogContent = [...state.blogContent, ...content];
    },
    SET_PAGE_SIZE(state, size) {
      state.pageSize = size;
    },
    SET_HEADER(state, headerContent) {
      state.headerContent = headerContent;
    },
    setAnalytics(state, value) {
      state.analytics = value;
    },
    SET_RANDOM_CHARACTER_QUOTE(state, quote) {
      state.randomCharacterQuote = quote;
    },
    SET_BLOG_CONTENT(state, { posts, append }) {
      if (append) {
        state.blogContent = [...state.blogContent, ...posts];
      } else {
        state.blogContent = posts;
      }
    },
    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
    SET_CURRENT_POST(state, post) {
      state.currentPost = post;
    },
    SET_HAS_MORE_POSTS(state, value) {
      state.hasMorePosts = value;
    },
    INCREMENT_PAGE(state) {
      state.currentPage++;
    },
  },
  actions: {
    async getProjectsContent({ state, commit }) {
      if (!state.projectsContent.length) {
        try {
          const response = await fetch("/.netlify/functions/projectsContent");
          const data = await response.json();
          commit("SET_PROJECTS", data.projectsContent.results.reverse());
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getAboutContent2({ state, commit }) {
      if (!state.aboutContent2.length) {
        try {
          const response = await fetch("/.netlify/functions/aboutContent2");
          const data = await response.json();
          commit("SET_ABOUT2", data.aboutContent2.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getAboutContent({ state, commit }) {
      if (!state.aboutContent.length) {
        try {
          const response = await fetch("/.netlify/functions/aboutContent1");
          const data = await response.json();
          commit("SET_ABOUT", data.aboutContent1.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getExperiencesContent({ state, commit }) {
      if (!state.experiencesContent.length) {
        try {
          const response = await fetch(
            "/.netlify/functions/experiencesContent"
          );
          const data = await response.json();
          commit("SET_EXPERIENCE", data.experiencesContent.results.reverse());
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getDegreesContent({ state, commit }) {
      if (!state.degreesContent.length) {
        try {
          const response = await fetch("/.netlify/functions/degreesContent");
          const data = await response.json();
          commit("SET_DEGREES", data.degreesContent.results.reverse());
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getHeaderContent({ state, commit }) {
      if (!state.headerContent.length) {
        try {
          const response = await fetch("/.netlify/functions/headerContent");
          const data = await response.json();
          commit("SET_HEADER", data.headerContent.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async fetchRandomCharacterQuote({ state, commit }) {
      if (!state.randomCharacterQuote) {
        try {
          const response = await fetch("/.netlify/functions/quoteContent");
          const data = await response.json();
          commit("SET_RANDOM_CHARACTER_QUOTE", data.quoteContent);
        } catch (error) {
          console.error("Error fetching random character quote:", error);
        }
      }
    },
    async getBlogContent({ commit, state }, { size = 8, reset = false }) {
      try {
        const page = reset ? 1 : state.currentPage;
        const response = await fetch(
          `/.netlify/functions/blogContent?page=${page}&size=${size}`
        );
        const data = await response.json();

        if (data && data.blogContent && Array.isArray(data.blogContent)) {
          commit("SET_BLOG_CONTENT", {
            posts: data.blogContent,
            append: !reset,
          });
          commit("SET_CURRENT_PAGE", page + 1);
          commit("SET_HAS_MORE_POSTS", data.blogContent.length === size);
        } else {
          console.error("Unexpected data structure:", data);
          commit("SET_HAS_MORE_POSTS", false);
        }
      } catch (error) {
        console.error("Error fetching blog content:", error);
        commit("SET_HAS_MORE_POSTS", false);
      }
    },
    async fetchSinglePost({ commit }, slug) {
      try {
        const response = await fetch(
          `/.netlify/functions/singlePost?slug=${slug}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }
        commit("SET_CURRENT_POST", data);
        return data;
      } catch (error) {
        console.error("Error fetching single post:", error);
        throw error;
      }
    },
  },
  modules: {},
  // plugins: [vuexLocal.plugin], // Activation du plugin vuex-persist
});
