<template>
  <div>
    <template v-if="post">
      <article class="post container px-2 mt-3 mx-auto post-hidden-overflow">
        <div class="content-background-dotted">
          <header class="p-2 d-flex justify-content-between align-items-center">
            <router-link
              to="/blog"
              class="m-2 p-2 btn btn-dark btn-sm d-flex align-items-center"
              style="text-decoration: none"
            >
              <span class="me-1">👈️</span> Retour
            </router-link>
            <a
              class="m-2 btn btn-dark btn-sm d-flex justify-content-end"
              :href="'https://blog.benoitpetit.dev/newsletter'"
              target="_blank"
              rel="noopener noreferrer"
            >
              Suivez-moi
              <img
                :src="hashnodeLogo"
                height="25"
                width="25"
                alt="hashnode logo"
                loading="lazy"
                class="ms-1"
              />
            </a>
          </header>

          <img
            v-if="post.coverImage"
            :src="post.coverImage.url"
            :srcset="post.coverImage.srcset"
            sizes="(max-width: 600px) 100vw, 60vw"
            class="cover-img"
            :alt="post.title"
            loading="lazy"
          />

          <h1 v-once class="title-hero-page-post text-center">
            {{ post.title }}
          </h1>

          <div class="d-flex justify-content-center">
            <span
              v-for="tag in post.tags"
              :key="tag.name"
              class="badge bg-purple mx-2 text-dark"
            >
              {{ tag.name }}
            </span>
          </div>

          <small class="d-flex justify-content-center my-2 text-muted">
            {{ formatDate(post.publishedAt) }}
          </small>

          <div class="content-post-markdown">
            <div
              class="p-3 rounded content-post"
              v-html="sanitizedContent"
            ></div>
          </div>
        </div>
      </article>
    </template>

    <template v-else-if="error">
      <div class="container mt-4 text-center">
        <p class="text-danger">{{ error }}</p>
        <router-link to="/blog" class="btn btn-primary">
          Retour à la liste des articles
        </router-link>
      </div>
    </template>

    <template v-else>
      <div class="container mt-4">
        <div class="skeleton-loader">
          <div class="skeleton-header"></div>
          <div class="skeleton-image"></div>
          <div class="skeleton-title"></div>
          <div class="skeleton-tags"></div>
          <div class="skeleton-date"></div>
          <div class="skeleton-content"></div>
        </div>
      </div>
    </template>

    <footer class="container">
      <FooterComponent />
    </footer>

    <Transition name="fade">
      <div v-if="showPopup" class="popup-overlay" @click.self="closePopup">
        <div class="popup-content shadow">
          <h2>Merci d'avoir lu cet article !</h2>
          <p>Vous avez aimé ? Ne manquez pas les prochains posts !</p>
          <div class="button-container">
            <a
              class="btn btn-post-end btn-primary"
              href="https://benoitpetit.hashnode.dev/newsletter"
              target="_blank"
              rel="noopener noreferrer"
            >
              S'abonner à la newsletter
            </a>
            <a
              class="btn btn-post-end btn-bmc"
              href="https://www.buymeacoffee.com/benoitpetit"
              target="_blank"
              rel="noopener noreferrer"
            >
              Me Soutenir 💖
            </a>
            <button @click="closePopup" class="btn btn-post-end btn-secondary">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  computed,
  defineAsyncComponent,
  onUnmounted,
  nextTick,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useSeoMeta } from "@unhead/vue";
import DOMPurify from "dompurify";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/panda-syntax-dark.css";

import loadingFile from "../assets/images/loading.gif";
import hashnodeLogo from "../assets/images/hashnode-logo.png";

const FooterComponent = defineAsyncComponent(() =>
  import("@/components/FooterComponent")
);

export default {
  name: "PostView",
  components: { FooterComponent },
  setup() {
    const route = useRoute();
    const store = useStore();
    const post = ref(null);
    const error = ref(null);
    const showPopup = ref(false);
    const isContentLoaded = ref(false);

    const pageTitle = computed(() =>
      post.value ? `Ben | ${post.value.title}` : "Ben | Blog & Tutos"
    );
    const pageDescription = computed(() => post.value?.brief);
    const pageImage = computed(() => post.value?.coverImage?.url);

    useSeoMeta({
      title: pageTitle,
      description: pageDescription,
      author: "Benoit Petit",
      publisher: "Benoit Petit",
      articleTag: post.value?.tags.map((tag) => tag.name),

      // Open Graph Metadata (pour réseaux sociaux, Facebook, LinkedIn, etc.)
      ogSiteName: "Ben | Blog & Tutos",
      ogTitle: pageTitle,
      ogDescription: computed(() => {
        return (
          post.value?.brief ||
          "Découvrez une collection d'articles et de tutoriels sur des outils pratiques pour développeurs et utilisateurs techniques."
        );
      }),
      ogUrl: computed(
        () => `https://benoitpetit.dev/blog/${route.params.slug}`
      ),
      ogImage: pageImage,
      ogType: "article",

      link: {
        rel: "canonical",
        href: computed(
          () => `https://benoitpetit.dev/blog/${route.params.slug}`
        ),
      },

      // Twitter Metadata
      twitterCard: computed(() =>
        post.value?.coverImage ? "summary_large_image" : "summary"
      ),
      twitterTitle: pageTitle,
      twitterDescription: pageDescription,
      twitterImage: pageImage,
      twitterImageAlt: computed(
        () => post.value?.title || "Image de couverture de l'article"
      ),
      twitterSite: "@devbyben",

      // Meta Keywords
      keywords: computed(
        () =>
          post.value?.tags.map((tag) => tag.name).join(", ") ||
          "blog, articles, tutoriels, développement"
      ),

      // Meta Robots (indexation)
      robots: "index, follow",
    });

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    };

    const processImageInMarkdown = (content) => {
      return content.replace(
        /!\[(.*?)\]\((.*?)\s*(?:"(.*?)")?\s*\)(?:\s*\{([^}]*)\})?/g,
        (match, alt, src, title, attributes) => {
          // Nettoyer l'URL de l'image en supprimant l'attribut align s'il est présent
          const cleanedSrc = src.replace(/\s+align="?[^"]*"?$/, "");

          // Extraire l'alignement de l'URL ou des attributs
          let align = "center"; // Valeur par défaut
          const srcAlignMatch = src.match(/align="?([^"]*)"?$/);
          const attributesAlignMatch = attributes?.match(
            /align="?(.*?)"?(?:\s|$)/
          );

          if (srcAlignMatch) {
            align = srcAlignMatch[1];
          } else if (attributesAlignMatch) {
            align = attributesAlignMatch[1];
          }

          return `<img src="${cleanedSrc}" alt="${alt}" title="${
            title || ""
          }" class="marked-image" style="display: block; margin: 0 auto; max-width: 100%; height: auto; border-radius: 15px;" align="${align}" loading="lazy">`;
        }
      );
    };

    const sanitizedContent = computed(() => {
      if (!post.value?.content.markdown) return "";
      const processedContent = processImageInMarkdown(
        post.value.content.markdown
      );
      const rawHtml = marked(processedContent);
      return DOMPurify.sanitize(rawHtml);
    });

    const fetchPost = async () => {
      error.value = null;
      try {
        post.value = await store.dispatch("fetchSinglePost", route.params.slug);

        if (!post.value) throw new Error("L'article n'a pas été trouvé.");

        nextTick(() => {
          highlightCode();
          isContentLoaded.value = true;
        });
      } catch (err) {
        console.error("Error fetching post:", err);
        error.value = `Une erreur s'est produite lors du chargement de l'article: ${err.message}`;
      }
    };

    const highlightCode = () => {
      document.querySelectorAll("pre code").forEach((el) => {
        hljs.highlightElement(el);
      });
    };

    const closePopup = () => {
      showPopup.value = false;
    };

    const checkScroll = () => {
      if (!isContentLoaded.value) return;

      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (
        scrollPosition + windowHeight >= documentHeight - 100 &&
        !showPopup.value
      ) {
        showPopup.value = true;
      } else if (
        scrollPosition < documentHeight - windowHeight - 100 &&
        showPopup.value
      ) {
        showPopup.value = false;
      }
    };

    onMounted(() => {
      fetchPost();
    });

    watch(isContentLoaded, (newValue) => {
      if (newValue) {
        window.addEventListener("scroll", checkScroll);
      }
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", checkScroll);
    });

    return {
      post,
      error,
      loadingFile,
      hashnodeLogo,
      formatDate,
      sanitizedContent,
      showPopup,
      closePopup,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Wellfleet&display=swap");
.hero-post {
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.title-hero-page-post {
  max-width: 50%;
  margin: 1rem auto;
}

.cover-img {
  display: block;
  margin: 1rem auto;
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;
  border-radius: 10px;
}

.content-post {
  background-color: #2d2f3d;
  border-radius: 30px;
  max-width: 60%;
  margin: 0 auto;
  color: #dfe1ec;
  font-size: 1rem;
}

.content-post-markdown {
  background-color: #2d2f3d;
  padding: 30px;
}

.content-post :deep(h1),
.content-post :deep(h2),
.content-post :deep(h3),
.content-post :deep(h4),
.content-post :deep(h5),
.content-post :deep(h6) {
  font-family: "Wellfleet", cursive;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--foreground);
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.content-post :deep(p) {
  margin-bottom: 1em;
}

.content-post :deep(img) {
  display: block;
  margin: 0 auto;
  padding: 30px 0;
  max-width: 100%;
  width: auto;
}

.content-post :deep(blockquote) {
  padding: 20px 20px 8px 20px;
  font-style: italic;
  color: #a5a8bd;
  background-color: #262833;
  border-radius: 10px;
  margin: 1em 0;
  border-left: 4px solid #ccc;
}

.content-post :deep(pre) {
  background-color: #2a2c2d;
  border-radius: 15px;
  padding: 1em;
  overflow-x: auto;
  margin: 1em 0;
}

.content-post :deep(code) {
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #2d2f3d;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  width: 400px;
}

.popup-content h2 {
  color: #fff;
  margin-bottom: 1rem;
}

.popup-content p {
  color: #dfe1ec;
  margin-bottom: 1.5rem;
}

.btn-post-end {
  width: 100%;
  max-width: 250px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.skeleton-loader {
  max-width: 100%;
  margin: 0 auto;
}

.skeleton-header,
.skeleton-image,
.skeleton-title,
.skeleton-tags,
.skeleton-date,
.skeleton-content {
  background: linear-gradient(90deg, #2d2f3d 25%, #3a3c4e 50%, #2d2f3d 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeleton-header {
  height: 40px;
  margin-bottom: 20px;
}

.skeleton-image {
  height: 200px;
  margin-bottom: 20px;
}

.skeleton-title {
  height: 40px;
  margin-bottom: 20px;
}

.skeleton-tags {
  height: 30px;
  margin-bottom: 20px;
}

.skeleton-date {
  height: 20px;
  width: 150px;
  margin: 0 auto 20px;
}

.skeleton-content {
  height: 400px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media (max-width: 600px) {
  .content-post {
    max-width: 100%;
    margin: 0 auto;
  }
  .content-post-markdown {
    padding: 0px;
  }
  .cover-img {
    max-width: 100%;
    border-radius: 0;
  }
  .title-hero-page-post {
    max-width: 100%;
  }
  .btn {
    width: 100%;
  }
  .skeleton-loader {
    max-width: 100%;
  }
}

.post-hidden-overflow {
  overflow: hidden;
}
</style>
