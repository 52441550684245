<template>
  <footer class="footer mt-5 pt-3">
    <div class="container">
      <hr class="divider" />
      <div class="row my-5">
        <div class="col-md-4">
          <h2 class="title-second">Qui suis-je ?</h2>
          <hr class="divider" />

          <p class="text-muted">
            Moi c'est Ben, Concepteur & Développeur d'applications freelance
            basé à Lille, France. Passionné par la tech, la programmation, et la
            collection de stickers de dev.
          </p>
        </div>
        <div class="col-md-4">
          <h2 class="title-second">Compétences</h2>
          <hr class="divider" />
          <div class="skills-container">
            <div
              v-for="skill in skills"
              :key="skill.name"
              class="skill-bubble"
              @click="toggleSkillInfo(skill)"
              :class="{ active: skill.showInfo }"
            >
              <i :class="skill.icon"></i>
              <span class="skill-name">{{ skill.name }}</span>
              <div
                v-if="skill.showInfo"
                class="skill-info content-backgroud-dotted"
              >
                {{ skill.description }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h2 class="title-second">Contact</h2>
          <hr class="divider" />

          <p class="text-muted">
            Vous souhaitez me contacter pour poser des questions, partager vos
            commentaires, demander des conseils ou discuter d'un projet ?
          </p>
          <button
            class="btn btn-pink"
            data-tally-open="mBMX7n"
            data-tally-layout="modal"
            data-tally-width="502"
            data-tally-emoji-text="🤘 "
            data-tally-emoji-animation="bounce"
            data-tally-auto-close="3000"
          >
            Contactez moi
          </button>
        </div>
      </div>
      <hr class="divider" />
      <div class="social-links text-center my-4">
        <h2 class="title-second text-muted mb-3">Retrouvez-moi sur</h2>
        <div class="d-flex justify-content-center flex-wrap">
          <a
            v-for="link in socialLinks"
            :key="link.url"
            :href="link.url"
            target="_blank"
            class="social-icon"
          >
            <i :class="link.icon"></i>
          </a>
        </div>
      </div>
      <hr class="divider" />
      <div class="text-center mt-4">
        <img
          :src="powered"
          height="30"
          width="326"
          alt="powered logo"
          class="powered mt-1"
          title="powered logo"
        />
        <p class="text-muted mt-2">
          code with ❤️ by
          <a href="https://x.com/@devbyben" class="link-style" target="_blank"
            >Benoit Petit</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { reactive } from "vue";
import powered from "../assets/images/powered.webp";

export default {
  name: "FooterComponent",
  setup() {
    const skills = reactive([
      {
        name: "Node.js",
        description:
          "Développement backend robuste, création d'APIs RESTful performantes, développement d'outils en ligne de commande, gestion asynchrone efficace.",
        icon: "bi bi-hdd-network",
        showInfo: false,
      },
      {
        name: "Golang",
        description:
          "Conception et implémentation de microservices hautement évolutifs, développement d'applications concurrentes pour des performances optimales, utilisation efficace de goroutines et channels pour la programmation parallèle.",
        icon: "bi bi-cpu",
        showInfo: false,
      },
      {
        name: "Vue.js",
        description:
          "Création d'applications web monopage (SPA) réactives et performantes, développement d'interfaces utilisateur dynamiques et interactives, utilisation avancée de la composition API et du state management avec Vuex.",
        icon: "bi bi-diagram-3",
        showInfo: false,
      },
      {
        name: "Docker",
        description:
          "Conteneurisation d'applications pour un déploiement cohérent, avec orchestration de conteneurs, mise en place de pipelines CI/CD automatisés, optimisation des images Docker pour des déploiements rapides et sécurisés.",
        icon: "bi bi-boxes",
        showInfo: false,
      },
      {
        name: "Automatisation",
        description:
          "Création de scripts Shell avancés pour l'automatisation des tâches système, configuration et maintenance, automatisation et gestion de workflows pour une gestion de code efficace et sécurisée.",
        icon: "bi bi-gear",
        showInfo: false,
      },
      {
        name: "Web Scraping",
        description:
          "Développement de scrapers web robustes et éthiques, interaction avec diverses APIs RESTful et GraphQL, analyse et traitement de contenu web à grande échelle, utilisation de techniques d'anti-détection, de rotation de proxies.",
        icon: "bi bi-funnel",
        showInfo: false,
      },
      {
        name: "Linux",
        description:
          "Administration avancée de serveurs Linux, mise en place de mesures de sécurité robustes (pare-feu, SELinux), optimisation des performances système, gestion efficace des ressources et troubleshooting approfondi.",
        icon: "bi bi-terminal",
        showInfo: false,
      },
    ]);

    const socialLinks = [
      { url: "https://x.com/devbyben", icon: "bi bi-twitter-x" },
      { url: "https://t.me/benoitpetit", icon: "bi bi-telegram" },
      {
        url: "https://www.linkedin.com/in/benoit-petit-110",
        icon: "bi bi-linkedin",
      },
      { url: "https://github.com/benoitpetit", icon: "bi bi-github" },
      {
        url: "https://www.npmjs.com/~codingben",
        icon: "bi bi-box2-heart-fill",
      },
      {
        url: "https://blog.benoitpetit.dev/newsletter",
        icon: "bi bi-substack",
      },
    ];

    const toggleSkillInfo = (skill) => {
      skills.forEach((s) => {
        if (s !== skill) s.showInfo = false;
      });
      skill.showInfo = !skill.showInfo;
    };

    return {
      powered,
      skills,
      socialLinks,
      toggleSkillInfo,
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: var(--background);
  color: var(--foreground);
}

.divider {
  border-color: var(--foreground);
  opacity: 0.2;
}

.text-muted {
  font-size: 0.9rem;
  color: var(--text-muted);
  font-family: "Fira Code", monospace;
}

.title-second {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--foreground);
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.skill-bubble {
  background-color: #ff79c6;
  color: #282a36;
  border-radius: 20px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.skill-bubble:hover {
  background-color: #ff92d0;
  color: #44475a;
}

.skill-bubble.active {
  background-color: #bd93f9;
  color: #1f1f1f;
}

.skill-bubble i {
  margin-right: 8px;
}

.skill-name {
  font-size: 14px;
  font-weight: bold;
}

.skill-info {
  position: absolute;
  top: 100%;
  left: 0;
  /* background: linear-gradient(to top, rgb(211, 211, 211), #ff92d0); */
  background: #d8d4d4;
  font-family: "Fira Code", monospace;
  border-radius: 10px;
  padding: 10px;
  font-size: 0.9rem;
  width: 200px;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  color: var(--primary);
}

.social-icon {
  font-size: 1.2rem;
  margin: 0 0.5rem;
  color: var(--foreground);
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: violet;
}

.powered {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.powered:hover {
  filter: grayscale(0%);
}
</style>
