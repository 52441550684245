<template>
  <div class="newsletter-form card content-background-dotted p-0 shadow mt-5">
    <div class="card-body p-4">
      <h3 class="text-center" style="color: var(--foreground)">
        Abonnez-vous à ma newsletter
      </h3>
      <p class="text-center" style="color: var(--comment)">
        Recevez mes derniers articles et astuces directement dans votre boîte
        mail.
      </p>
      <div v-if="!formSubmitted">
        <form
          @submit.prevent="handleNewsletterSubmit"
          class="d-flex flex-column align-items-center"
        >
          <div class="input-group mb-3" style="max-width: 500px; width: 100%">
            <input
              v-model="newsletterEmail"
              type="email"
              class="form-control"
              placeholder="Votre adresse e-mail"
              required
              style="
                background-color: var(--background);
                color: var(--foreground);
              "
            />
            <button
              type="submit"
              class="btn btn-primary"
              style="
                background-color: var(--purple);
                border: none;
                color: var(--foreground);
              "
            >
              S'abonner
            </button>
          </div>

          <div class="form-check mb-3" style="max-width: 500px; width: 100%">
            <input
              v-model="newsletterAgreement"
              type="checkbox"
              class="form-check-input"
              id="newsletterAgreement"
              required
            />
            <label
              class="form-check-label"
              for="newsletterAgreement"
              style="color: var(--foreground)"
            >
              J'accepte de recevoir des e-mails de la part de ce blog.
            </label>
          </div>

          <p class="text-muted" style="font-size: 0.9rem">
            sponsored by Hashnode
          </p>
        </form>
      </div>
      <p
        v-if="newsletterStatus"
        class="text-center mt-3"
        :style="{ color: newsletterStatusColor }"
      >
        {{ newsletterStatusMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getFromCacheSimple, setInCacheSimple } from "../utils/cache";

export default {
  name: "NewsletterComponent",
  setup() {
    const newsletterEmail = ref("");
    const newsletterAgreement = ref(false);
    const newsletterStatus = ref("");
    const newsletterStatusMessage = ref("");
    const newsletterStatusColor = ref("var(--foreground)");
    const formSubmitted = ref(false);

    const handleNewsletterSubmit = async () => {
      if (!newsletterAgreement.value) {
        newsletterStatusMessage.value =
          "Veuillez accepter de recevoir des e-mails.";
        newsletterStatusColor.value = "var(--red)";
        newsletterStatus.value = true;
        return;
      }

      const cacheKey = `newsletter_${newsletterEmail.value}`;
      const cachedResponse = getFromCacheSimple(cacheKey);
      if (cachedResponse) {
        // Utiliser la réponse mise en cache
        newsletterStatusMessage.value = cachedResponse.message;
        newsletterStatusColor.value = cachedResponse.color;
        formSubmitted.value = true;
        return;
      }

      try {
        const response = await fetch(
          "/.netlify/functions/subscribeNewsletter",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: newsletterEmail.value,
              consent: newsletterAgreement.value,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          let message = "";
          let color = "";
          switch (data.status) {
            case "CONFIRMED":
              message = "Merci de vous être abonné !";
              color = "var(--green)";
              break;
            case "PENDING":
              message =
                "Vérifiez votre e-mail pour confirmer votre abonnement.";
              color = "var(--yellow)";
              break;
            case "ALREADY_SUBSCRIBED":
              message = "Vous êtes déjà abonné à la newsletter.";
              color = "var(--orange)";
              break;
            default:
              message =
                "Une erreur est survenue. Veuillez réessayer plus tard.";
              color = "var(--red)";
          }
          // Stocker la réponse dans le cache
          setInCacheSimple(cacheKey, { message, color });

          newsletterStatusMessage.value = message;
          newsletterStatusColor.value = color;
          // Réinitialiser les champs du formulaire
          newsletterEmail.value = "";
          newsletterAgreement.value = false;
          formSubmitted.value = true;
        } else {
          throw new Error(data.message || "Une erreur est survenue");
        }
      } catch (error) {
        console.error("Erreur lors de l'inscription:", error);
        newsletterStatusMessage.value =
          "Une erreur est survenue. Veuillez réessayer plus tard.";
        newsletterStatusColor.value = "var(--red)";
      }
      newsletterStatus.value = true;
    };

    return {
      newsletterEmail,
      newsletterAgreement,
      newsletterStatus,
      newsletterStatusMessage,
      newsletterStatusColor,
      handleNewsletterSubmit,
      formSubmitted,
    };
  },
};
</script>
