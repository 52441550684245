<template>
  <div>
    <nav
      class="px-0 pt-4 d-flex justify-content-center nav-link text-light bg-selection-lite shadow"
    >
      <ul
        class="nav text-nowrap flex-nowrap pb-4"
        data-aos="flip-right"
        style="overflow-x: auto"
      >
        <li
          v-for="(link, index) in sanitizedNavLinks"
          :key="index"
          class="nav-item p-1"
        >
          <router-link
            :class="['link-style m-3 p-1', { active: isActiveRoute(link.to) }]"
            :to="link.to"
          >
            <i :class="link.icon"></i>
            <span v-html="link.text"></span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="banner" v-if="shouldShowPopup">
      <div
        class="banner-content bg-white rounded-lg shadow-lg p-6 fixed bottom-0 left-0 right-0"
      >
        <p class="font-bold mb-4" v-html="sanitizedBannerTitle"></p>
        <small class="text-muted mb-4" v-html="sanitizedBannerText"></small
        ><br />
        <small v-html="sanitizedBannerSubtext"></small>
        <br />
        <div
          class="button-container d-flex flex-column flex-md-row justify-content-between mt-3"
        >
          <button
            class="btn btn-secondary py-2 px-4 mt-2 mt-md-0 rounded-full text-white font-bold hover:bg-pink-600 transition-colors duration-300"
            @click="refuseAnalytics"
          >
            Refuser
          </button>
          <button
            class="btn btn-pink py-2 px-4 mt-2 mt-md-0 rounded-full text-white font-bold hover:bg-pink-600 transition-colors duration-300"
            @click="acceptAnalytics"
          >
            Accepter tout
          </button>
          <button
            class="btn btn-outline-secondary py-2 px-4 mt-2 mt-md-0 rounded-full font-bold hover:bg-gray-200 transition-colors duration-300"
            @click="customizePreferences"
          >
            Personnaliser
          </button>
        </div>
        <div v-if="showCustomization" class="customization-options mt-4">
          <p class="font-bold">Préférences des cookies :</p>
          <label>
            <input type="checkbox" v-model="necessaryCookies" disabled />
            Cookies nécessaires (toujours actifs)
          </label>
          <br />
          <label>
            <input type="checkbox" v-model="analyticsCookies" />
            Cookies analytiques
          </label>
          <br />
          <label>
            <input type="checkbox" v-model="marketingCookies" />
            Cookies marketing
          </label>
          <br />
          <button
            class="btn btn-pink py-2 px-4 mt-3 rounded-full text-white font-bold hover:bg-pink-600 transition-colors duration-300"
            @click="savePreferences"
          >
            Sauvegarder les préférences
          </button>
        </div>
      </div>
    </div>
    <div v-if="$route.matched.length > 0" class="router-view">
      <router-view />
    </div>
    <div v-else>
      <NotFoundView></NotFoundView>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NotFoundView from "@/views/NotFoundView";
import { useHead, useSeoMeta } from "@unhead/vue";
import DOMPurify from "dompurify";

export default {
  name: "App",
  components: { NotFoundView },
  setup() {
    const store = useStore();
    const route = useRoute();
    const showPopup = ref(true);
    const showCustomization = ref(false);
    const analyticsCookies = ref(false);
    const marketingCookies = ref(false);
    const necessaryCookies = ref(true);
    const title = ref("Ben | Concepteur & Développeur d'applications web");
    const description = ref(
      `Créateur et bidouilleur numérique passionné, concepteur et développeur
      d'application web. Découvrez mon parcours, mes projets, mon blog, mes compétences`
    );

    const navLinks = [
      { to: "/", icon: "bi bi-house-fill", text: " Accueil" },
      { to: "/blog", icon: "bi bi-substack", text: " Blog & Tutos" },
      { to: "/projects", icon: "bi bi-kanban-fill", text: " Projects" },
      { to: "/sh", icon: "bi bi-terminal", text: " Terminal" },
    ];

    const sanitizedNavLinks = computed(() =>
      navLinks.map((link) => ({
        ...link,
        text: DOMPurify.sanitize(link.text),
      }))
    );

    const isActiveRoute = (path) => {
      if (path === "/") {
        return route.path === "/";
      }
      return route.path.startsWith(path);
    };

    const sanitizedBannerTitle = computed(() =>
      DOMPurify.sanitize("Consentement aux cookies 🍪")
    );

    const sanitizedBannerText = computed(() =>
      DOMPurify.sanitize(
        'Nous utilisons des cookies pour améliorer votre expérience, analyser le trafic et vous proposer des contenus personnalisés. En cliquant sur "Accepter tout", vous acceptez l\'utilisation de tous les cookies. Vous pouvez aussi personnaliser vos préférences.'
      )
    );

    const sanitizedBannerSubtext = computed(() =>
      DOMPurify.sanitize(
        "Vous pouvez changer d'avis à tout moment en modifiant vos paramètres de cookies."
      )
    );

    onBeforeMount(() => {
      const contentTypes = [
        "aboutContent",
        "aboutContent2",
        "experiencesContent",
        "degreesContent",
        "projectsContent",
        "headerContent",
      ];
      contentTypes.forEach((type) => {
        if (!store.state[type])
          store.dispatch(`get${type.charAt(0).toUpperCase() + type.slice(1)}`);
      });
    });

    const shouldShowPopup = computed(
      () => document.cookie.indexOf("analytics=true") === -1 && showPopup.value
    );

    const acceptAnalytics = () => {
      document.cookie =
        "analytics=true; path=/; max-age=31536000; SameSite=Strict; Secure";
      showPopup.value = false;
      store.commit("setAnalytics", true);
    };

    const refuseAnalytics = () => {
      document.cookie =
        "analytics=false; path=/; max-age=31536000; SameSite=Strict; Secure";
      showPopup.value = false;
      store.commit("setAnalytics", false);
      window.location.href = "https://google.com";
    };

    const customizePreferences = () => {
      showCustomization.value = true;
    };

    const savePreferences = () => {
      document.cookie = `analytics=${analyticsCookies.value}; path=/; max-age=31536000; SameSite=Strict; Secure`;
      document.cookie = `marketing=${marketingCookies.value}; path=/; max-age=31536000; SameSite=Strict; Secure`;
      showPopup.value = false;
      store.commit("setAnalytics", analyticsCookies.value);
    };

    useHead({
      link: [
        {
          rel: "canonical",
          type: "url",
          href: "https://benoitpetit.dev/index.html",
        },
      ],
      meta: [
        {
          name: "keywords",
          content:
            "Benoit Petit, Portfolio, Développeur web, Golang, Node.js, Vue.js, ben, benoit, petit, développeur, concepteur, web, dev, programmation, blog, benoitpetit.dev",
        },
      ],
    });

    useSeoMeta({
      title: title.value,
      author: "Benoit Petit",
      description: description.value,
      searchDescription: "Ben | Concepteur Développeur d'Applications web",
      ogUrl: "https://benoitpetit.dev",
      ogDescription: description.value,
      ogLocale: "fr_FR",
      ogLocaleAlternate: "en_US",
      ogTitle: title.value,
      ogImage: "/assets/card.png",
      ogType: "website",
      ogImageUrl: "/assets/card.png",
      ogSiteName: title.value,
      twitterCard: "/assets/card.png",
      twitterSite: "@codingben_",
      twitterDescription: description.value,
      twitterTitle: title.value,
      twitterImage: "/assets/card.png",
      twitterImageAlt: "Ben | Concepteur Développeur d'Applications web",
      robots: "index, follow",
    });

    return {
      NotFoundView,
      showPopup,
      shouldShowPopup,
      acceptAnalytics,
      refuseAnalytics,
      sanitizedNavLinks,
      sanitizedBannerTitle,
      sanitizedBannerText,
      sanitizedBannerSubtext,
      isActiveRoute,
      showCustomization,
      customizePreferences,
      savePreferences,
      analyticsCookies,
      marketingCookies,
      necessaryCookies,
    };
  },
};
</script>

<style>
/* Les styles restent inchangés */
.nav-link {
  z-index: 7;
  padding: 0;
}

.nav-item {
  color: #fff;
  font-size: 16px;
}

nav {
  padding: 0;
}

.router-view {
  width: 100%;
  margin: 0 auto;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  color: #000;
}

.banner-content {
  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: 30%;
  padding: 1.5rem;
  margin: auto;
  bottom: 0;
  position: fixed;
}

.button-container {
  gap: 1rem;
}

.btn-pink {
  background-color: #ff6b6b;
}

.btn-pink:hover {
  background-color: #ff4757;
}

@media screen and (max-width: 600px) {
  .banner-content {
    max-width: 90%;
  }
  .button-container {
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>
