<template>
  <div class="container mt-4">
    <!-- Intégration du composant NewsletterComponent -->
    <NewsletterComponent />
    <hr style="color: var(--foreground)" />

    <div data-aos="fade-zoom" data-aos-delay="100">
      <div v-if="blogContent.length" class="posts row mt-3">
        <div
          v-for="post in blogContent"
          :key="post.slug"
          class="col-xs-12 col-sm-6 col-md-4 col-xl-3 p-0 mt-3"
        >
          <article class="card content-background-dotted m-2 p-0 shadow">
            <router-link
              :to="{ name: 'blog-post', params: { slug: post.slug } }"
              class="card-img-link"
            >
              <img
                :src="post.coverImage.url"
                class="card-img-top"
                :alt="post.title"
                loading="lazy"
              />
            </router-link>
            <div class="card-body card-content-post">
              <h2 class="h5">
                <router-link
                  :to="{ name: 'blog-post', params: { slug: post.slug } }"
                  class="link-style"
                >
                  {{ post.title }}
                </router-link>
              </h2>
              <p class="card-text content-mono mb-0">{{ post.brief }}</p>
              <p class="text-purple mt-0">
                {{ post.tags.map((tag) => tag.name).join(", ") }}
              </p>
            </div>
          </article>
        </div>
      </div>

      <p v-else-if="!isLoading" class="text-center mt-4">
        Aucun article trouvé
      </p>

      <div v-if="isLoading" class="posts row mt-3">
        <div
          v-for="n in 8"
          :key="n"
          class="col-xs-12 col-sm-6 col-md-4 col-xl-3 p-0 mt-3"
        >
          <div class="card m-2 p-0 shadow skeleton-card">
            <div class="skeleton-img"></div>
            <div class="card-body">
              <div class="skeleton-title"></div>
              <div class="skeleton-text"></div>
              <div class="skeleton-tags"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasMorePosts && !isLoading" class="text-center mt-4">
        <button
          @click="loadMorePosts"
          type="load"
          class="btn btn-primary"
          style="
            background-color: var(--purple);
            border: none;
            color: var(--foreground);
          "
        >
          Charger plus
          <i class="bi bi-arrow-repeat"></i>
        </button>
      </div>
    </div>

    <FooterComponent />
    <input type="text" name="honeypot" id="honeypot" style="display: none" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useSeoMeta } from "@unhead/vue";
import DOMPurify from "dompurify";
import FooterComponent from "../components/FooterComponent.vue";
import NewsletterComponent from "../components/NewsletterComponent.vue";
import hashnodeLogo from "../assets/images/hashnode-logo.png";

export default {
  name: "BlogListView",
  components: {
    FooterComponent,
    NewsletterComponent,
  },
  setup() {
    const store = useStore();
    const hashnodeUsername = "benoitpetit";
    const isLoading = ref(false);
    const currentPage = ref(1);
    const pageSize = 8;

    const sanitize = (content) => DOMPurify.sanitize(content);

    const blogContent = computed(() => store.state.blogContent);

    const hasMorePosts = computed(
      () => blogContent.value.length % pageSize === 0
    );

    const loadBlogContent = async () => {
      isLoading.value = true;
      await store.dispatch("getBlogContent", {
        page: currentPage.value,
        size: pageSize,
      });
      isLoading.value = false;
    };

    const loadMorePosts = async () => {
      if (hasMorePosts.value && !isLoading.value) {
        currentPage.value++;
        await loadBlogContent();
      }
    };

    const title = ref("Ben | Blog & Tutos");
    const description = ref(
      `Plongez dans mon espace personnel ici c'est un carrefour d'informations où je partage des astuces utiles et explore les tendances émergentes du monde numérique.`
    );

    useSeoMeta({
      title: sanitize(title.value),
      description: sanitize(description.value),
      ogTitle: sanitize(title.value),
      ogDescription: sanitize(description.value),
      ogUrl: sanitize("https://benoitpetit.dev/my-blog"),
      ogType: sanitize("article"),
      ogSiteName: sanitize(title.value),
      twitterDescription: sanitize(description.value),
      twitterTitle: sanitize(title.value),
    });

    onMounted(() => {
      if (!store.state.blogContent.length) {
        loadBlogContent();
      }
    });

    return {
      hashnodeLogo,
      hashnodeUsername,
      isLoading,
      hasMorePosts,
      loadMorePosts,
      blogContent,
    };
  },
};
</script>

<style scoped>
.skeleton-card {
  height: 100%;
  background: linear-gradient(90deg, #2d2f3d 25%, #3a3c4e 50%, #2d2f3d 75%);
}

.skeleton-img,
.skeleton-title,
.skeleton-text,
.skeleton-tags {
  background: linear-gradient(90deg, #2d2f3d 25%, #3a3c4e 50%, #2d2f3d 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeleton-img {
  height: 200px;
}

.skeleton-title {
  height: 24px;
  margin-bottom: 10px;
}

.skeleton-text {
  height: 80px;
  margin-bottom: 10px;
}

.skeleton-tags {
  height: 20px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
