<template>
  <section class="projects">
    <div class="second-title content-background-title-dotted py-4 my-3">
      <h1 class="text-center title-hero-page">
        Projets <i class="bi bi-kanban-fill" aria-hidden="true"></i>
      </h1>
      <p class="text-center text-muted pt-2">
        Une liste de projets indéfiniment extensible, <br />
        je continuerai demain !
      </p>
    </div>
    <div class="content-background-dotted p-2 tablflow shadow">
      <table
        v-if="projectsContent.length"
        class="tb-size table table-sm text-secondary"
      >
        <thead>
          <tr class="text-secondary border-secondary">
            <th class="text-purple" scope="col">N°</th>
            <th class="text-purple" scope="col">Projet</th>
            <th class="text-purple" scope="col">Description</th>
            <th class="text-purple" scope="col">Stack</th>
            <th class="text-purple" scope="col">Progression</th>
            <th class="text-purple" scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in projectsContent"
            :key="item.id"
            class="text-secondary border-secondary"
          >
            <td>#{{ padProjectNumber(projectsContent.length - index) }}</td>
            <td>
              <a
                :href="item.field_334715"
                target="_blank"
                rel="noopener noreferrer"
                class="link-style"
              >
                {{ item.field_334711 }}
              </a>
            </td>
            <td>{{ item.field_334714 }}</td>
            <td>
              <small class="badge badge-stack">{{ item.field_334713 }}</small>
              <div class="bdg">
                <span
                  v-for="badge in item.field_336094"
                  :key="badge.id"
                  class="badge badge-pill badge-stack m-1"
                >
                  {{ badge.value }}
                </span>
              </div>
            </td>
            <td>
              <div
                class="progress"
                style="background-color: #bd93f907; height: 20px"
              >
                <div
                  class="progress-bar"
                  style="background-color: #44475a"
                  role="progressbar"
                  :style="{ width: item.field_1499401 + '%' }"
                  :aria-valuenow="item.field_1499401"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ item.field_1499401 }}%
                </div>
              </div>
            </td>
            <td>{{ formatDate(item.field_1496681) }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else-if="isLoading" class="smooth">
        <p class="text-light text-center mt-3">
          Les données sont en cours de chargement ...
        </p>
        <p class="text-light text-center">
          Si rien ne s'affiche actualiser la page 👍️
        </p>
        <div class="d-flex justify-content-center mt-3">
          <img
            :src="loadingFile"
            alt="loading"
            height="70"
            width="70"
            loading="lazy"
          />
        </div>
      </div>
      <p v-else class="text-center text-light">
        Aucun projet disponible pour le moment.
      </p>
    </div>
  </section>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import loadingFile from "@/assets/images/loading.gif";

export default {
  name: "ProjectsComponent",
  setup() {
    const store = useStore();

    const projectsContent = computed(() => store.state.projectsContent);
    const isLoading = computed(() => store.state.isLoading);

    const padProjectNumber = (num) => String(num).padStart(3, "0");

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    return {
      projectsContent,
      isLoading,
      loadingFile,
      padProjectNumber,
      formatDate,
    };
  },
};
</script>

<style scoped>
.tablflow {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 1.8rem;
  max-height: 100rem;
  padding: 20px;
}

@media screen and (max-width: 600px) {
  .tb-size {
    min-width: 130vh;
  }
}

.footer-table {
  min-width: 130vh;
  margin-bottom: 0.5rem;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

td,
th {
  padding: 12px;
  vertical-align: middle;
}
</style>
